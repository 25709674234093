import React from "react";

const IconUTD = (props) => (
	<svg fill="none" xmlns="http://www.w3.org/2000/svg" width="58" height="20" viewBox="0 0 58 20" {...props}>
		<path
			d="M2.826 0v12.042c0 1.415.28 2.591.84 3.528.83 1.415 2.228 2.122 4.196 2.122 2.36 0 3.964-.765 4.813-2.294.457-.831.685-1.95.685-3.356V0h2.826v10.942c0 2.396-.34 4.24-1.02 5.53-1.25 2.352-3.61 3.528-7.08 3.528s-5.824-1.176-7.065-3.528C.341 15.182 0 13.338 0 10.942V0h2.826zM36.897 0v2.321h-6.925v17.162h-2.826V2.32h-6.925V0h16.676zM48.78 17.228c.942 0 1.717-.093 2.323-.278 1.082-.345 1.968-1.008 2.658-1.99.55-.787.947-1.795 1.19-3.024.14-.733.209-1.414.209-2.042 0-2.414-.508-4.288-1.525-5.623C52.628 2.935 51 2.268 48.753 2.268h-4.939v14.96h4.967zM41.017 0h8.324c2.826 0 5.018.95 6.575 2.851C57.306 4.567 58 6.764 58 9.443c0 2.069-.41 3.939-1.231 5.61-1.446 2.953-3.931 4.43-7.457 4.43h-8.296V0z"
			fill="#fff"
		/>
	</svg>
);

export default IconUTD;
