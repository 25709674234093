import React from 'react';
import cn from 'classnames';

const Scrollable = ({
  vertical = false,
  horizontal = false,
  isHeightUnset = true,
  className,
  children,
  ...props
}, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        'scrollable',
        vertical && 'scrollable--vertical',
        horizontal && 'scrollable--horizontal',
        isHeightUnset && 'scrollable--height-unset',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default React.forwardRef(Scrollable);
