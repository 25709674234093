import React from "react";
import PropTypes from "prop-types";

const SideMenuContacts = ({ contacts }) => {
	if(!contacts.length) {
		return null;
	}

	return (
		<div className="side-menu__contacts">
			{contacts.map((contact, index) => (
				<a
					key={index}
					aria-label={`Contact us at ${contact.label}`}
					href={contact.link}
					className="side-menu__contact"
					dangerouslySetInnerHTML={{
						__html: `${contact.icon}${contact.label}`
					}}
				/>
			))}
		</div>
	);
};

SideMenuContacts.propTypes = {
	contacts: PropTypes.array.isRequired
};

export default SideMenuContacts;
