import React from "react";

const IconPlay = (props) => (
	<svg
		width="28"
		height="28"
		viewBox="0 0 28 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-hidden={true}
		{...props}
	>
		<path d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z" fill="white"/>
		<path d="M18.5192 13.1362C19.1807 13.5221 19.1807 14.4779 18.5192 14.8638L12.5039 18.3727C11.8372 18.7616 11 18.2808 11 17.509L11 10.491C11 9.71925 11.8372 9.23837 12.5039 9.62726L18.5192 13.1362Z" fill="black"/>
	</svg>
);

export default IconPlay;

