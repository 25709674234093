import React, {useState} from "react";
import {Text} from "../index";
import {Link} from "gatsby";
import handleize from "../../helpers/handleize";
import IconCheck from "../../icons/IconCheck";
import IconCross from "../../icons/IconCross";
import cn from "classnames";
import {AnimatePresence, motion} from "framer-motion";

const PlansTable = ({
    plans,
    withoutHeader = false,
}) => {
    const [activeCol, setActiveCol] = useState(0);

    const renderHeader = () => {
        return (
            <tr className="plans-table__row">
                <th className="plans-table__head-cell plans-table__head-cell--gray">
                    Choose your plan
                </th>

                {plans.map(({title, plan}, index) => (
                    <React.Fragment key={index}>
                        <th
                            className={cn(
                                "plans-table__head-cell",
                                activeCol === index && "plans-table__head-cell--active",
                            )}
                            onMouseEnter={() => setActiveCol(index)}
                        >
                            <span className="plans-table__column-gradient"/>

                            <Text
                                as="p"
                                appearance="paregraph"
                                className="plans-table__plan-label"
                            >
                                {title}
                            </Text>

                            <Text
                                as="p"
                                appearance="heading4"
                                className="plans-table__plan-price"
                            >
                                {plan.price}
                            </Text>

                            <Link
                                to={plan.link.url}
                                className="button plans-table__contact-button"
                            >
                                {plan.link.title}
                            </Link>
                        </th>
                    </React.Fragment>
                ))}
            </tr>
        );
    };

    const renderRows = () => {
        const allListsWithDuplicate = plans.reduce((acc, {plan}) => [
            ...acc,
            ...plan.benefits.reduce((acc, benefit) => [
                ...acc,
                benefit,
            ], [])
        ],[]);

        let uniqueValues = {};

        const allLists = allListsWithDuplicate.map(({label}) => {
            return {
                label,
                benefit: allListsWithDuplicate
                    .filter(list => list.label === label)
                    .reduce((acc, list) => {
                        return [
                            ...acc,
                            ...list.benefit,
                        ]
                    }, [])
                    .filter(({label}) => {
                        if (uniqueValues.hasOwnProperty(handleize(label))) {
                            return false;
                        } else {
                            uniqueValues[handleize(label)] = true;
                            return true;
                        }
                    })
            }
        }).filter(({label}) => {
            if (uniqueValues.hasOwnProperty(handleize(label))) {
                return false;
            } else {
                uniqueValues[handleize(label)] = true;
                return true;
            }
        });

        return allLists.map(({label: listLabel, benefit}, index) => {
            return (
                <React.Fragment key={index}>
                    <tr className="plans-table__row plans-table__row--list-label">
                        <td className="plans-table__cell">
                            {listLabel}
                        </td>

                        {plans.map((plan,index) => (
                            <td
                                className={cn(
                                    "plans-table__cell",
                                    activeCol === index && "plans-table__cell--active",
                                )}
                                onMouseEnter={() => setActiveCol(index)}
                            />
                        ))}
                    </tr>

                    {benefit.map(({label: benefitLabel}, index) => {
                        return (
                            <tr
                                key={index}
                                className="plans-table__row"
                            >
                                <td
                                    className="plans-table__cell"
                                    dangerouslySetInnerHTML={{__html: benefitLabel}}
                                />

                                {plans.map(({ plan }, index) => {
                                    const currentBenefit = plan.benefits
                                        .find(({label}) => label === listLabel)
                                        ?.benefit.find(({label}) => label === benefitLabel)
                                    let value = null;

                                    if (currentBenefit) {
                                        switch (currentBenefit.type) {
                                            case 'boolean':
                                                value = Boolean(currentBenefit.valueBoolean);
                                                break;
                                            case 'string':
                                                value = currentBenefit.valueStr;
                                                break;
                                            case 'empty':
                                            default:
                                                value = null;
                                        }
                                    }

                                    return (
                                        <td
                                            className={cn(
                                                "plans-table__cell",
                                                activeCol === index && "plans-table__cell--active",
                                            )}
                                            key={index}
                                            onMouseEnter={() => setActiveCol(index)}
                                        >
                                            {value !== null && (
                                                <AnimatePresence>
                                                    <motion.div
                                                        className="plans-table__cell-content"
                                                        initial="hidden"
                                                        animate="visible"
                                                        exit="exit"
                                                        transition={{ duration: 0.3, delay: 0 }}
                                                        variants={{
                                                            visible: { opacity: 1, scale: 1 },
                                                            hidden: { opacity: 0, scale: 0 },
                                                            exit: { opacity: 0, scale: 0 },
                                                        }}
                                                    >
                                                        {typeof value === 'boolean'
                                                            ? value
                                                                ? (
                                                                    <>
                                                                        <IconCheck />
                                                                        <span className="sr-only">Yes</span>
                                                                    </>
                                                                )
                                                                : (
                                                                    <>
                                                                        <IconCross />
                                                                        <span className="sr-only">No</span>
                                                                    </>
                                                                )
                                                            : value
                                                        }
                                                    </motion.div>
                                                </AnimatePresence>
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </React.Fragment>
            );
        });
    };

    return (
        <div className={cn(
            "plans-table__table-container",
            plans.length === 1 && "plans-table__table-container--two-col",
            plans.length === 2 && "plans-table__table-container--three-col",
            plans.length >= 3 && "plans-table__table-container--more-col",
        )}>
            <table className="plans-table__table">
                {!withoutHeader && (
                    <thead>{renderHeader()}</thead>
                )}
                <tbody>{renderRows()}</tbody>
            </table>
        </div>
    );
};

export default PlansTable;
