const quarterSpin = {
  hidden: {
    rotate: '-90deg',
    opacity: 0,
  },
  visible: {
    rotate: '0deg',
    opacity: 1,
    transition: {
      duration: 0.3,
      type: 'spring',
    },
  },
  exit: {
    rotate: '-90deg',
    opacity: 0,
  },
};

export default quarterSpin;
