import React, { forwardRef } from "react";
import classNames from "classnames";
import IconClose from "../../icons/IconClose";
import {graphql, Link, useStaticQuery} from "gatsby";
import ContactButton from "../Contact/ContactButton";
import SideMenuContacts from "./SideMenuContacts";
import SideMenuSocials from "./SideMenuSocials";
import SideMenuNavigation from "./SideMenuNavigation";
import IconUTD from "../../icons/IconUTD";

const SideMenu = ({ isSideMenuOpened, closeSideMenu }, ref) => {
	const { allWp, wpMenu: menu } = useStaticQuery(graphql`
		query SideMenu {
			allWp {
				nodes {
					sideMenu {
						acfSideMenu {
							contactButtonText
						}
					}
					contacts {
						acfContacts {
							phone
							contacts {
								icon
								label
								link
							}
						}
					}
					socialLinks {
						acfSocialLinks {
							socialLinks {
								text
								url
								ariaLabel
								icon
							}
						}
					}
				}
			}
			wpMenu(locations: {eq: SIDEBAR}) {
				menuItems {
					nodes {
						id
						url
						label
					}
				}
			}
		}
	`);

	const settings 				= allWp?.nodes?.[0] ?? {};
	const contactButtonText 	= settings.sideMenu?.acfSideMenu?.contactButtonText;
	const acfContacts			= settings.contacts?.acfContacts ?? {};
	const contacts 				= acfContacts.contacts ?? [];
	const socialLinks 			= settings.socialLinks?.acfSocialLinks?.socialLinks ?? [];
	const menuItems 			= menu?.menuItems?.nodes ?? [];
	const KEYCODE = {
		ESC: 27,
	}
	function onKeyDown(event) {
		if (event.keyCode === KEYCODE.ESC) {
			closeSideMenu();
		}
	}

	const sideMenuClasses = classNames({
		"side-menu": true,
		"side-menu--opened": isSideMenuOpened
	});

	return (
		<div className={sideMenuClasses} onKeyDown={onKeyDown}>
			<div
				className="side-menu__wrapper"
				ref={ref}
			>
				<div className="side-menu__header">
					<Link to="/" className="header__logo-link" aria-label="Go to homepage">
						<IconUTD className="header__logo" />
					</Link>

					<button
						className="side-menu__close-button"
						type="button"
						onClick={closeSideMenu}
						aria-label="Close menu"
					>
						<IconClose />
					</button>
				</div>

				<div className="side-menu__content">
					<div className="side-menu__column side-menu__column--contacts">
						{/*{phone && (*/}
						{/*	<a*/}
						{/*		className="side-menu__phone-number"*/}
						{/*		href={`tel:${phone.replace(/ /g, '')}`}*/}
						{/*		aria-label="Phone"*/}
						{/*	>*/}
						{/*		{phone}*/}
						{/*	</a>*/}
						{/*)}*/}

						<SideMenuContacts contacts={contacts} />

						<SideMenuSocials socialLinks={socialLinks} />

						<ContactButton
							contactButtonText={contactButtonText}
							className="side-menu__contact-button hide-mobile"
						/>
					</div>

					<div className="side-menu__column side-menu__column--navigation">
						<SideMenuNavigation menuItems={menuItems} />

						<ContactButton
							contactButtonText={contactButtonText}
							className="side-menu__contact-button hide-tablet-and-up"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default forwardRef(SideMenu);
