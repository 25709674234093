const accordion = {
  hidden: {
    height: 0,
  },
  visible: {
    height: 'auto',
  },
  exit: {
    height: 0,
  },
};

export default accordion;
