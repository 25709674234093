import React, {useEffect, useRef, useState} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import cn from 'classnames';
import {
  Card,
  Overlay,
  Scrollable,
  Text,
  TrapFocus,
  KeyEventHandler,
  CloseButton,
} from '../';
import * as animations from '../../animations';
import Key from '../../helpers/KeyCodes';
import useBodyScrollLock from '../../hooks/useBodyScrollLockUpgrade';

const Modal = ({
  isOpened = false,
  isCloseButtonShown = false,
  isRounded = true,
  isBordered = true,
  isSpaced = true,
  title = '',
  animation = 'slideUp', // 'fadeIn' | 'fadeScale' | 'flip' | 'newspaper' | 'slideUp' | 'slideDown' | 'slideLeft' | 'slideRight' | 'accordion' | 'swipe'
  children,
  className,
  onOpen,
  onClose,
  outerClassName,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpened);
  const [prevTarget, setPrevTarget] = useState(null);
  const scrollableRef = useRef(null);
  const [isLocked, lock, unlock] = useBodyScrollLock(scrollableRef);

  const updateLock = (isOpened) => {
    if (!scrollableRef || !scrollableRef.current) return;

    if (isOpened && !isLocked) {
      lock(scrollableRef);
    } else if (!isOpened) {
      unlock(scrollableRef);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
    onClose && onClose();
  };

  const handleClick = (event) => {
    event.stopPropagation();

    if (event.target && event.target.tagName === 'A') {
      handleClose();
    }
  }

  useEffect(() => {
    setIsModalOpen(isOpened);
  }, [isOpened]);

  useEffect(() => {
    if (isModalOpen) {
      onOpen && onOpen();
    }

    updateLock(isModalOpen);

    if (prevTarget) {
      setTimeout(() => {
        prevTarget.focus();
        setPrevTarget(null);
      }, 300);
    }
  }, [isModalOpen]);

  return (
    <AnimatePresence>
      {isModalOpen &&
        <KeyEventHandler keyCode={Key.Escape} event="keydown" handler={handleClose}>
          <Overlay onClick={handleClose}>
            <TrapFocus className={cn(
                "trap-focus trap-focus--modal",
                outerClassName,
            )} role="dialog" {...props}>
              <motion.div
                onClick={handleClick}
                variants={animations[animation]}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <Card
                  className={cn('modal', className)}
                  isRounded={isRounded}
                  isBordered={isBordered}
                  isSpaced={isSpaced}
                >
                  <header className={cn("modal__header", !title && 'modal__header--empty')}>
                    {title
                        ? typeof title === 'string' ? (
                            <Text as="h2" appearance="heading3">
                              {title}
                            </Text>
                        ) : title
                        : null
                    }

                    <CloseButton
                      onClick={handleClose}
                      className={cn(
                        'modal__close',
                        !isCloseButtonShown && 'sr-only'
                      )}
                    />
                  </header>

                  <Scrollable vertical ref={scrollableRef}>
                    {children}
                  </Scrollable>
                </Card>
              </motion.div>
            </TrapFocus>
          </Overlay>
        </KeyEventHandler>
      }
    </AnimatePresence>
  );
};

export default Modal;
