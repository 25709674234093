import React from "react";

const IconCross = (props) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-hidden="true"
		{...props}
	>
		<path d="M14.999 5L4.99902 15" stroke="#DD2929" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M4.99902 5L14.999 15" stroke="#DD2929" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
);

export default IconCross;

