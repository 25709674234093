import React from 'react';
import cn from 'classnames';

const Card = ({
  as = 'div', // 'div' | 'section' | 'article' | 'aside' | 'header' | 'footer' | 'nav' | 'ul' | 'ol' | 'dl'
  isRounded = false,
  isFull = false,
  isBordered = false,
  isSpaced = false,
  className,
  children,
  ...props
}) => {
  const Element = as || 'div';

  return (
    // @ts-ignore
    <Element
      className={cn(
        'card',
        isRounded && 'card--rounded',
        isFull && 'card--full',
        isBordered && 'card--bordered',
        isSpaced && 'card--spaced',
        className,
      )}
      {...props}
    >
      {children}
    </Element>
  );
};

export default Card;
