import React, {useContext, useEffect, forwardRef, useRef} from "react";
import PropTypes from "prop-types";
import { ContactPopupContext } from "../../providers/ContactPopupProvider";
import classNames from "classnames";
import IconClose from "../../icons/IconClose";
import ContactForm from "./ContactForm";
import FocusLock from 'react-focus-lock';

const ContactPopup = forwardRef(({ title, description }, ref) => {
	const { contactPopupShow, closeContactPopup } = useContext(ContactPopupContext);

	const formRef = useRef();

	useEffect(() => {
		const closeOnEscapeKey = (e) => e.key === 'Escape' ? closeContactPopup() : null;

		document.body.addEventListener('keydown', closeOnEscapeKey);

		return () => {
			document.body.removeEventListener('keydown', closeOnEscapeKey);
		}
	}, [])

	const contactPopupClasses = classNames({
		"contact-popup": true,
		"contact-popup--shown": contactPopupShow
	});

	return (
		<FocusLock>
			<div
				className={contactPopupClasses}
				role="dialog"
				aria-labelledby="popup-contactTitle"
				aria-describedby="popup-contactDescription"
			>
				<div
					className="contact-popup__overlay"
					onClick={closeContactPopup}
					aria-hidden="true"
				/>

				<div className="contact-popup__wrapper" ref={ref}>
					<div className="contact-popup__form-wrapper" ref={formRef}>
						<button
							className="contact-popup__close-button"
							type="button"
							onClick={closeContactPopup}
							aria-label="Close"
						>
							<IconClose />
						</button>

						<ContactForm title={title} description={description} ariaParent={'popup'}/>
					</div>
				</div>
			</div>
		</FocusLock>
	);
});

ContactPopup.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string
};

export default ContactPopup;
