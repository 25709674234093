import React from "react";

const IconClose = () => (
	<svg
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		width="14"
		height="14"
		viewBox="0 0 14 14"
		aria-hidden={true}
	>
		<path d="M14 .825L13.175 0 7 6.175.825 0 0 .825 6.175 7 0 13.175.825 14 7 7.825 13.175 14l.825-.825L7.825 7 14 .825z" fill="#fff"/>
	</svg>
);

export default IconClose;
