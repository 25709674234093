import React, { useEffect } from 'react';

const KeyEventHandler = ({
  event,
  keyCode,
  handler,
  children,
  targetRef,
}) => {
  const keyIsArray = Array.isArray(keyCode);
  const keysArray = keyIsArray
    ? [...keyCode]
    : [keyCode];

  const handleKeyEvent = (event) => {
    if (keysArray.includes(event.keyCode)) {
      handler && handler();
    }
  };

  useEffect(() => {
    const target = targetRef?.current || document;

    target.addEventListener(event, handleKeyEvent);
    return () => {
      target.removeEventListener(event, handleKeyEvent);
    };
  }, [handleKeyEvent]);

  return <>{children}</>;
};

export default KeyEventHandler;
