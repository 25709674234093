import React, {useCallback, useEffect, useState} from "react";
import { graphql, useStaticQuery } from "gatsby";
import {Modal, CustomCard} from "../components";
import ContactPopup from "../components/Contact/ContactPopup";
import {ContactPopupContext} from "../providers/ContactPopupProvider";

const CustomPopup = () => {
    const { allWp } = useStaticQuery(graphql`
        query CustomPopup {
            allWp {
                nodes {
                    customPopup {
                        acfCustomPopup {
                            description
                            enablePopup
                            image {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                                    }
                                }
                            }
                            link {
                                target
                                title
                                url
                            }
                            showAfter
                            title
                        }
                    }
                }
            }
        }
    `);
    const acfCustomPopup = allWp?.nodes[0]?.customPopup?.acfCustomPopup;

    const [showPopup, setShowPopup] = useState(false);

    const handleClose = useCallback(() => {
        sessionStorage.setItem("CustomPopup", "shown");
        setShowPopup(false);
    }, []);

    const checkShow = () => !(sessionStorage.getItem("CustomPopup") === 'shown');

    useEffect(() => {
        setTimeout(() => {
            if (typeof window !== 'undefined') {
                setShowPopup(checkShow());
            }
        }, acfCustomPopup.showAfter * 1000);
    }, []);

    return (
        <>
            {acfCustomPopup && acfCustomPopup.enablePopup
                ? (
                    <Modal
                        isOpened={showPopup}
                        onOpen={() => setShowPopup(true)}
                        onClose={handleClose}
                        className="custom-popup"
                        isRounded={false}
                        isBordered={false}
                        isSpaced={false}
                        isCloseButtonShown={true}
                    >
                        <CustomCard {...acfCustomPopup} />
                    </Modal>
                )
                : null
            }
        </>
    );
}

export default CustomPopup;
