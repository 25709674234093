import React from "react";
import Text from "../../Text";
import {Link} from "gatsby";
import cn from "classnames";

const Tabs = ({plans, onChange, activeTab}) => {
    return (
        <div className="plans-table__tabs-container">
            <Text
                as="p"
                appearance="heading6"
                className="plans-table__tabs-title"
            >
                Choose your plan
            </Text>

            <div className="plans-table__tabs">
                {plans.map(({plan, title}, index) => {
                    return (
                        <div className={cn(
                            "plans-table__tab",
                            plans.length === 1 && "plans-table__tab--full",
                            plans.length > 2 && "plans-table__tab--less-half",
                            activeTab === index && "plans-table__tab--active",
                        )}>
                            <div className="plans-table__column-gradient" />

                            <button
                                key={index}
                                onClick={() => onChange(index)}
                                className="plans-table__tab-button"
                            >
                                <Text
                                    as="p"
                                    appearance="paregraph"
                                    className="plans-table__plan-label"
                                >
                                    {title}
                                </Text>

                                <Text
                                    as="p"
                                    appearance="heading4"
                                    className="plans-table__plan-price"
                                >
                                    {plan.price}
                                </Text>
                            </button>

                            <Link
                                to={plan.link.url}
                                className="button plans-table__contact-button"
                            >
                                {plan.link.title}
                            </Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Tabs;
