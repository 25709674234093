import React from "react";

const IconRightArrow = (props) => (
	<svg
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 17 16"
		aria-hidden="true"
		{...props}
	>
		<path d="M16.707 8.707a1 1 0 000-1.414L10.343.929A1 1 0 108.93 2.343L14.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h16V7H0v2z" fill="currentColor"/>
	</svg>
);

export default IconRightArrow;
