const tabsSection= {
  container: {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  },
  fadeIn: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {duration: 0.5},
    },
  },
  slideUp: {
    hidden: {
      opacity: 0,
      y: '100px',
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {duration: 0.5},
    },
  },
  rollOut: {
    hidden: {
      transform: 'translateX(100%) rotate(90deg)',
      opacity: 0,
      scale: 0.3
    },
    show: {
      transform: 'translateX(0) rotate(0deg)',
      opacity: 1,
      scale: 1
    },
  }
};

export default tabsSection;
