export const FOCUSABLE_SELECTOR =
  'a,frame,iframe,input:not([type=hidden]):not(:disabled),select:not(:disabled),textarea:not(:disabled),button:not([aria-disabled="true"]):not([tabindex="-1"]):not(:disabled),*[tabindex]:not([tabindex="-1"])';

export const findFirstFocusableNode = (element) => {
  return element && element.querySelector(FOCUSABLE_SELECTOR);
};

export const focusFirstFocusableNode = (element) => {
  findFirstFocusableNode(element)?.focus();
};

export const getAllFocusableNodes = (element) => {
  return element && element.querySelectorAll(FOCUSABLE_SELECTOR);
};
