const opacitySlideUp = {
  hidden: {
    height: 0,
    opacity: 0
  },
  visible: {
    height: 'auto',
    opacity: 1
  },
  exit: {
    height: 0,
    opacity: 0
  },
};

export default opacitySlideUp;
