import { useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default function useBodyScrollLock(contentWrapper) {
	const [isLocked, setLockState] = useState(false);

	const lock = () => {
		setLockState(true);

		disableBodyScroll(contentWrapper.current);
	};

	const unlock = () => {
		setLockState(false);

		enableBodyScroll(contentWrapper.current);
	};

	return [
		isLocked,
		lock,
		unlock
	];
}
