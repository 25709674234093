import React from "react";
import PropTypes from "prop-types";
import parse from 'html-react-parser';

const SideMenuSocials = ({ socialLinks }) => {
	if(!socialLinks.length) {
		return null;
	}

	return (
		<div className="side-menu__socials">
			{socialLinks.map(({ url, text, ariaLabel, icon }, index) => (
				<a
					key={index}
					href={url}
					className="side-menu__social"
					target="_blank"
					rel="noreferrer"
					aria-label={`Visit our ${ariaLabel}. Link will be opened in a new tab.`}
				>
					{parse(icon)}
				</a>
			))}
		</div>
	);
}

SideMenuSocials.propTypes = {
	socialLinks: PropTypes.array.isRequired
};

export default SideMenuSocials;
