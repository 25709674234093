import React, {RefObject, useState} from 'react';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock-upgrade';

const useScrollLock = () => {
	const [isLocked, setLockState] = useState(false);

	const lock = (scrollable) => {
		setLockState(true);

		if (scrollable.current) {
			disableBodyScroll(scrollable.current);
		}
	};

	const unlock = (scrollable) => {
		setLockState(false);

		if (scrollable.current) {
			enableBodyScroll(scrollable.current);
		}
	};

	return [isLocked, lock, unlock];
};

export default useScrollLock;
