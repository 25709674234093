import React from 'react';
import cn from 'classnames';
import IconClose from '../../icons/IconClose';

const CloseButton = ({children, className, ...props}) => {
  return (
    <button
      className={cn('', className)}
      type="button"
      aria-label="Close"
      {...props}
    >
      {children || <Cross />}
    </button>
  );
};

const Cross = () => {
  return (
    <span>
      <IconClose />
    </span>
  );
};

export default CloseButton;
