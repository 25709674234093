import React from 'react';
import {motion} from 'framer-motion';
import {fadeIn} from '../../../animations';

const Overlay = ({children, onClick}) => {
  return (
    <motion.div
      onClick={onClick}
      className="overlay"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {children}
    </motion.div>
  );
};

export default Overlay;
