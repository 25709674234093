import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Link} from "gatsby";

const ContactButton = ({ contactButtonText, className }) => {
	if(!contactButtonText) {
		return null;
	}

	const classes = classNames("button button--contact", className);

	return (
		<Link to="/contact-us/" className={classes}>
			{contactButtonText}
		</Link>
	);
}

ContactButton.propTypes = {
	contactButtonText: PropTypes.string,
	className: PropTypes.string
};

export default ContactButton;
