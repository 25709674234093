import React, {useState, useRef, useEffect} from 'react';
import classNames from "classnames";

const CustomDropdown = ({options, selectedOptions, onSelect, multiSelect = false}) => {

    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (option) => {
        if (multiSelect) {
            if (selectedOptions) {
                const isUncheck = selectedOptions.includes(option.value);
                const newSelectedOptions = isUncheck ? selectedOptions.filter(selected => selected !== option.value) : [...selectedOptions, option.value];

                onSelect(newSelectedOptions);
                return;
            }

            onSelect([option.value]);
        } else {
            setIsOpen(false);
            onSelect(option.value);
        }
    };

    const toggleDropdown = () => setIsOpen((prevIsOpen) => !prevIsOpen);

    const handleClickAway = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickAway, true);

        return () => {
            document.removeEventListener('click', handleClickAway, true);
        };
    }, []);

    const selectOptionMessage = multiSelect ? 'Select an option(s)...' : 'Select an option...';

    return (
        <div className="custom-dropdown" ref={dropdownRef}>
            <div className="selected-option" onClick={toggleDropdown}>
                {
                    selectedOptions?.length > 0 ? (
                        multiSelect ? <ul className={"selected-option-multiple-list"}>
                            {
                                selectedOptions.map(option => <li className={"selected-option-multiple-list-item"}>{option}</li>)
                            }
                        </ul> : selectedOptions.map(option => option).join(', ')
                    ) : <span>{selectOptionMessage}</span>
                }

                <svg
                    width="18"
                    height="18"
                    viewBox="0 0 1024 1024"
                    className={classNames({'rotate': isOpen})}
                >
                    <path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#000000"/>
                </svg>
            </div>
            <ul className={classNames('options', {'open': isOpen})}>
                {options.map((option) => (
                    <li
                        key={option.value}
                        className="options__item"
                        onClick={() => handleOptionClick(option)
                    }>
                        <span>
                            {option.label}
                        </span>

                        {selectedOptions && selectedOptions?.find(selected => selected === option.value) && multiSelect && <span style={{fontSize: "18px"}}>&#10003;</span>}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CustomDropdown;
