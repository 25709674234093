import React, {useCallback, useEffect, useState} from "react"
import {graphql, useStaticQuery} from "gatsby";
import {Modal, ReleaseNotesCard} from "../components";

const POPUP_CONFIG = {
    releaseType: 'global-last', // 'global-last' | 'last-in-article' | 'static-version',
    article: null,
    version: null,
    interval: 1, // Days
    showAfter: 10,
}

function parseDate(dateString) {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day);
}

function getCookie(name) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
}

function setCookie(name, value, days) {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}

function checkCookie() {
    const lastReleaseCookie = getCookie('last-release');

    return !lastReleaseCookie || lastReleaseCookie !== 'shown';
}

const ReleaseNotesPopup = () => {
    const { allWpReleaseNotes } = useStaticQuery(graphql`
        query ReleaseNotes {
            allWpReleaseNotes {
                nodes {
                    uri
                    title
                    release_notes {
                        releases {
                            date
                            description
                            version
                            media {
                                altText
                                sourceUrl
                                mediaType
                                mimeType
                                mediaItemUrl
                                mediaDetails {
                                    file
                                }
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                                    }
                                }
                            }
                            videoPoster {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    const articles = allWpReleaseNotes?.nodes;

    const [releaseType, setReleaseType] = useState(POPUP_CONFIG.releaseType);
    const [articleWithLatestRelease, setArticleWithLatestRelease] = useState(POPUP_CONFIG.article);
    const [latestRelease, setLatestRelease] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const handleClose = useCallback(() => {
        const { interval } = POPUP_CONFIG;
        setCookie('last-release', 'shown', interval);
        setShowPopup(false);
    }, []);

    useEffect(() => {
        switch (releaseType) {
            case 'global-last':
                if (articles && articles.length) {
                    let latestReleaseDate = new Date(1900, 0, 1);
                    let latestRelease = null;
                    let articleWithLatestRelease = null;

                    articles.forEach((article) => {
                        article.release_notes.releases?.forEach((release) => {
                            const currentReleaseDate = parseDate(release.date);
                            if (currentReleaseDate.getTime() > latestReleaseDate.getTime()) {
                                latestReleaseDate = currentReleaseDate;
                                articleWithLatestRelease = article;
                                latestRelease = release
                            }
                        });
                    });

                    setArticleWithLatestRelease(articleWithLatestRelease || null);
                    setLatestRelease(latestRelease || null);
                }

                break;
            case 'last-in-article':
                if (articleWithLatestRelease) {
                    let latestReleaseDate = new Date(1900, 0, 1);
                    let latestRelease = null;

                    articleWithLatestRelease.release_notes.releases?.forEach((release) => {
                        const currentReleaseDate = parseDate(release.date);
                        if (currentReleaseDate.getTime() > latestReleaseDate.getTime()) {
                            latestReleaseDate = currentReleaseDate;
                            latestRelease = release
                        }
                    });

                    if (latestRelease) {
                        setLatestRelease(latestRelease);
                    } else {
                        setReleaseType('global-last');
                    }
                } else {
                    setReleaseType('global-last');
                }

                break;
            case 'static-version':
                if (articleWithLatestRelease) {
                    if (POPUP_CONFIG.version) {
                        const currentRelease = articleWithLatestRelease.release_notes.releases
                            ?.find((release) => release.version === POPUP_CONFIG.version);

                        if (currentRelease) {
                            setLatestRelease(currentRelease);
                        } else {
                            setReleaseType('last-in-article');
                        }
                    } else {
                        setReleaseType('last-in-article');
                    }
                } else {
                    setReleaseType('global-last');
                }

                break;
        }
    }, [releaseType]);

    useEffect(() => {
        setTimeout(() => {
            if (typeof window !== 'undefined') {
                const isCurrentPage = window.location.href.includes(articleWithLatestRelease?.uri);

                if (articleWithLatestRelease && !isCurrentPage) {
                    setShowPopup(checkCookie());
                }
            }
        }, POPUP_CONFIG.showAfter * 1000);
    }, [articleWithLatestRelease]);

    return (
        <>
            {articleWithLatestRelease && latestRelease
                ? (
                    <Modal
                        isOpened={showPopup}
                        onOpen={() => setShowPopup(true)}
                        onClose={handleClose}
                        outerClassName="trap-focus--mobile-full"
                        className="release-notes-popup"
                        isRounded={false}
                        isBordered={false}
                        isSpaced={false}
                        isCloseButtonShown={true}
                    >
                        <ReleaseNotesCard
                            article={articleWithLatestRelease}
                            release={latestRelease}
                        />
                    </Modal>
                )
                : null
            }
        </>
    );
}

export default ReleaseNotesPopup;
