import React, {forwardRef} from 'react';
import cn from 'classnames';

const Text = (
  {
    // TextTag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'em' | 'u'
    // BlockTag: 'div' | 'section' | 'article' | 'aside' | 'header' | 'footer' | 'nav' | 'ul' | 'ol' | 'dl'
    as, // TextTag | BlockTag
    children,
    appearance, // 'heading1' | 'heading2' | 'heading3' | 'heading4' | 'heading5' | 'heading6' | 'paragraph'
    textStyle = 'normal', // 'normal' | 'italic' | 'bold'
    className,
    ...props
  },
  ref,
) => {
  const Component = as || 'p';

  const classes = cn(
      'text',
    appearance === 'heading1' && 'text--h1',
    appearance === 'heading2' && 'text--h2',
    appearance === 'heading3' && 'text--h3',
    appearance === 'heading4' && 'text--h4',
    appearance === 'heading5' && 'text--h5',
    appearance === 'heading6' && 'text--big',
    appearance === 'paragraph' && 'text--base',
    textStyle === 'normal' && 'text--normal',
    textStyle === 'italic' && 'text--italic',
    textStyle === 'bold' && 'text--bold',
    className,
  );

  return (
    <Component className={classes} {...props} ref={ref}>
      {children}
    </Component>
  );
};

export default forwardRef(Text);
