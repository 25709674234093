import React, {useEffect} from 'react';
import useTrapFocus from '../../../hooks/useTrapFocus';
import {focusFirstFocusableNode} from '../../../helpers/focus';

const TrapFocus = ({children, ...props}) => {
  const elRef = useTrapFocus();

  useEffect(() => {
    setTimeout(() => {
      elRef && elRef.current && focusFirstFocusableNode(elRef.current);
    }, 300);
  }, [elRef]);

  return (
    <div ref={elRef} {...props}>
      {children}
    </div>
  );
};

export default TrapFocus;
