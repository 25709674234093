import React from "react";

const IconCheck = (props) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-hidden="true"
		{...props}
	>
		<path d="M15.5907 6.00843C15.5133 5.93032 15.4211 5.86833 15.3195 5.82602C15.218 5.78371 15.1091 5.76193 14.9991 5.76193C14.8891 5.76193 14.7801 5.78371 14.6786 5.82602C14.577 5.86833 14.4849 5.93032 14.4074 6.00843L8.19906 12.2251L5.59073 9.60843C5.5103 9.53073 5.41534 9.46964 5.3113 9.42863C5.20725 9.38763 5.09615 9.36752 4.98433 9.36946C4.87251 9.37139 4.76217 9.39533 4.65961 9.43991C4.55704 9.48449 4.46426 9.54883 4.38656 9.62926C4.30886 9.7097 4.24777 9.80465 4.20677 9.9087C4.16576 10.0127 4.14565 10.1238 4.14759 10.2357C4.14952 10.3475 4.17346 10.4578 4.21804 10.5604C4.26262 10.663 4.32696 10.7557 4.4074 10.8334L7.6074 14.0334C7.68487 14.1115 7.77703 14.1735 7.87858 14.2158C7.98013 14.2581 8.08905 14.2799 8.19906 14.2799C8.30907 14.2799 8.41799 14.2581 8.51954 14.2158C8.62109 14.1735 8.71326 14.1115 8.79073 14.0334L15.5907 7.23343C15.6753 7.1554 15.7428 7.06069 15.789 6.95527C15.8352 6.84985 15.859 6.73602 15.859 6.62093C15.859 6.50585 15.8352 6.39201 15.789 6.28659C15.7428 6.18118 15.6753 6.08647 15.5907 6.00843Z" fill="#2FCA35"/>
	</svg>
);

export default IconCheck;

