const swipe = {
  hidden: {
    opacity: 1,
    right: 0,
    x: '250%',
    order: 3,
  },
  visible: {
    opacity: 1,
    x: '0',
    right: 0,
    transition: {
      duration: 0.3,
      type: 'spring',
      damping: 20,
      stiffness: 100,
    },
    order: 2,
  },
  exit: {
    opacity: 1,
    right: 0,
    x: '-250%',
    order: 1,
  },
};

export default swipe;
