import React from "react";
import {Link} from "gatsby";
import PropTypes from "prop-types";

const SideMenuNavigation = ({menuItems}) => {
    const getIndexWithLeadingZero = (index) => {
        return index < 10 ? `0${index}` : index;
    };

    return (
        <nav className="side-menu__navigation">
            <ul className="side-menu__navigation-items">
                <li className="side-menu__navigation-item">
                    <Link
                        to="/"
                        className={"side-menu__navigation-link"}
                        activeClassName={"side-menu__navigation-link--active"}
                        data-index={getIndexWithLeadingZero(1)}
                    >
                        Home
                    </Link>
                </li>
                {menuItems.map(({id, url, label}, index) => {
                    return (
                        index !== 0 ? <li key={id} className="side-menu__navigation-item">
                            <Link
                                to={url}
                                className={"side-menu__navigation-link"}
                                activeClassName={"side-menu__navigation-link--active"}
                                data-index={getIndexWithLeadingZero(index + 1)}
                                partiallyActive={true}
                            >
                                {label}
                            </Link>
                        </li> : null
                    );
                })}
            </ul>
        </nav>
    );
};

SideMenuNavigation.propTypes = {
    menuItems: PropTypes.array.isRequired
};

export default SideMenuNavigation;
