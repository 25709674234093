const fadeInSection = {
  container: {
    show: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  },
  items: {
    hidden: {
      opacity: 0,
      y: '50px',
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {duration: 0.4},
    },
  }
};

export default fadeInSection;
