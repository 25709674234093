import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ContactPopupProvider} from '../providers/ContactPopupProvider';
import {clearAllBodyScrollLocks} from 'body-scroll-lock';

import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import CustomPopup from '../sections/CustomPopup';
import Contact from '../sections/Contact';
import ReleaseNotesPopup from '../sections/ReleaseNotesPopup';
import {SendPulsePopup} from '../components';
import CookiesBarPopup from '../sections/CookiesBarPopup';

import cn from 'classnames';

const DefaultLayout = (
  {
    children,
    preFooterSections = null,
    isHeaderWithBlackText,
    className = '',
  },
) => {
  const [showContact, setShowContact] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setShowContact(!window.location.pathname.includes('contact-us'));
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  return (
    <ContactPopupProvider>
      <Header isBlackText={isHeaderWithBlackText}/>

      <div className={cn(className, 'content')}>
        <main className="main-content js-focus-hidden" id="MainContent" tabIndex="-1">
          <CustomPopup/>

          {children}

          {showContact &&
            <Contact/>
          }

          {preFooterSections}
        </main>

        <Footer/>
      </div>

      <CookiesBarPopup/>
      <SendPulsePopup/>
    </ContactPopupProvider>
  );
};

DefaultLayout.propTypes = {
  preFooterSections: PropTypes.element,
  isHeaderWithBlackText: PropTypes.bool,
};

export default DefaultLayout;
