import {useRef, useEffect, useCallback} from 'react';
import Key from '../helpers/KeyCodes';
import {getAllFocusableNodes} from '../helpers/focus';

const useTrapFocus = () => {
  const elRef = useRef(null);

  const handleFocus = useCallback(
    (e) => {
      if (typeof document === 'undefined') {
        return;
      }

      const focusableEls =
        elRef && elRef.current && getAllFocusableNodes(elRef.current);
      const firstFocusableEl = focusableEls && focusableEls[0];
      const lastFocusableEl =
        focusableEls && focusableEls[focusableEls.length - 1];

      const isTabPressed = e.key === 'Tab' || e.keyCode === Key.Tab;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        /* shift + tab */
        if (document?.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else {
        /* tab */
        if (document?.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    },
    [elRef],
  );

  useEffect(() => {
    elRef &&
      elRef.current &&
      elRef.current.addEventListener('keydown', handleFocus);

    return () => {
      elRef &&
        elRef.current &&
        elRef.current.removeEventListener('keydown', handleFocus);
    };
  }, [elRef]);

  return elRef;
};

export default useTrapFocus;
