import React, { createContext, useRef } from "react";
import ContactPopup from "../components/Contact/ContactPopup";
import { graphql, useStaticQuery } from "gatsby";
import useBodyScrollLock from "../hooks/useBodyScrollLock";

export const ContactPopupContext = createContext({
	contactPopupShow: false,
	showContactPopup: () => {},
	closeContactPopup: () => {}
});

export const ContactPopupProvider = ({ children }) => {
	const contactPopupScrollWrapper = useRef(null);
	const [contactPopupShow, showContactPopup, closeContactPopup] = useBodyScrollLock(contactPopupScrollWrapper);

	const { allWp } = useStaticQuery(graphql`
		query ContactPopup {
			allWp {
				nodes {
					contactPopup {
						acfContactPopup {
							title
							description
						}
					}
				}
			}
		}
	`);

	const acfContactPopup = allWp.nodes?.[0]?.contactPopup?.acfContactPopup || {};

	return (
		<ContactPopupContext.Provider
			value={{
				contactPopupShow,
				closeContactPopup,
				showContactPopup
			}}
		>
			<ContactPopup
				ref={contactPopupScrollWrapper}
				title={acfContactPopup.title}
				description={acfContactPopup.description}
			/>

			{children}
		</ContactPopupContext.Provider>
	);
};
