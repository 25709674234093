import React, {useCallback, useEffect, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import * as animations from "../animations";
import {Text} from "../components";
import {Link} from "gatsby";

const POPUP_CONFIG = {
  header: 'This website uses cookies',
  content: 'We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.',
  interval: 30, // Days
  showAfter: 2,
  animation: 'slideUp', // 'fadeIn' | 'fadeScale' | 'flip' | 'newspaper' | 'slideUp' | 'slideDown' | 'slideLeft' | 'slideRight' | 'accordion' | 'swipe'
}

function parseDate(dateString) {
  const [day, month, year] = dateString.split('/').map(Number);
  return new Date(year, month - 1, day);
}

function getCookie(name) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? match[2] : null;
}

function setCookie(name, value, days) {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}

function checkCookie() {
  const lastReleaseCookie = getCookie('accept-cookie');

  return !lastReleaseCookie || lastReleaseCookie !== 'true';
}

const CookiesBarPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleAccept = useCallback(() => {
    const { interval } = POPUP_CONFIG;
    setCookie('accept-cookie', 'true', interval);
    setShowPopup(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        setShowPopup(checkCookie());
      }
    }, POPUP_CONFIG.showAfter * 1000);
  }, []);

  return (
      <>
        <AnimatePresence>
          {showPopup && (
              <motion.div
                  className="cookies-bar"
                  onClick={(e) => e.stopPropagation()}
                  variants={animations[POPUP_CONFIG.animation]}
                  transition={{ duration: 0.8 }}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
              >
                <div className="cookies-bar__container">
                  <div className="cookies-bar__content">
                    <Text
                        className="cookies-bar__heading"
                        as="p"
                        appearance="paragraph"
                    >
                      {POPUP_CONFIG.header}
                    </Text>

                    <Text
                        className="cookies-bar__text"
                        as="p"
                        appearance="paragraph"
                    >
                      {POPUP_CONFIG.content}

                      <br/>
                      <Link to="/privacy-policy">
                        Privacy Policy
                      </Link>
                    </Text>
                  </div>

                  <button
                      className="button"
                      type="button"
                      onClick={handleAccept}
                  >
                    Accept
                  </button>
                </div>
              </motion.div>
          )}
        </AnimatePresence>
      </>
  );
}

export default CookiesBarPopup;
