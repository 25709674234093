import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import IconRightArrow from "../icons/IconRightArrow";
import { convertToBgImage } from "gbimage-bridge";

const Contact = () => {
	const { borderImage, allWp } = useStaticQuery(graphql`
		query {
			borderImage: file(relativePath: { eq: "button-ellipse.png" }) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
				}
			}
			allWp {
				nodes {
					contactUs {
						acfContactUs {
							buttonFirstTextPart
							buttonSecondTextPart
						}
					}
				}
			}
		}
	`);

	const { buttonFirstTextPart, buttonSecondTextPart } = allWp?.nodes?.[0]?.contactUs?.acfContactUs || {};

	if(!buttonFirstTextPart && !buttonSecondTextPart) {
		return null;
	}

	const image = borderImage?.childImageSharp?.gatsbyImageData;
	const backgroundImage = convertToBgImage(image);

	return (
		<section className="contact">
			<div className="contact__wrapper container">
				<Link to={'/contact-us/'} className="contact__link" aria-label="Go to contact us page">
					<span className="contact__title">
						{buttonFirstTextPart && (
							<span className="contact__title-text">
							{buttonFirstTextPart}
						</span>
						)}

						{buttonSecondTextPart && (
							<span className="contact__title-text contact__title-text--second">
							{buttonSecondTextPart}
						</span>
						)}
					</span>

					<span
						className="round-button contact__button"
						style={{
							"--border-image": `url("${backgroundImage?.fluid?.src}")`
						}}
					>
						<IconRightArrow />
					</span>
				</Link>
			</div>
		</section>
	);
};

export default Contact;
