import React from "react";
import IconScrollChevron from "../icons/IconScrollChevron";
import {useStaticQuery, graphql} from "gatsby";
import useMediaQuery from "../hooks/useMediaQuery";
import parse from 'html-react-parser';

const Footer = () => {
    const isAfterSmallBreakpoint = useMediaQuery("(min-width: 992px)");

    const {allWp} = useStaticQuery(graphql`
		query {
			allWp {
				nodes {
					footer {
						acfFooter {
							backTopButtonText
							copyright
							socialLabel
						}
					}
					socialLinks {
						acfSocialLinks {
							socialLinks {
								text
								url
								ariaLabel
								icon
							}
						}
					}
				}
			}
		}`);

    const settings = allWp?.nodes?.[0] ?? {};
    const footer = settings.footer?.acfFooter ?? {};
    const socialLinks = settings.socialLinks?.acfSocialLinks?.socialLinks ?? [];
    const {backTopButtonText, copyright, socialLabel} = footer;

    const currentYear = new Date().getFullYear().toString();
    let currentCopyright;
    if (copyright) {
        currentCopyright = copyright
            .replace('{{year}}', currentYear)
            .replace('2020', currentYear);
    }

    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__container">
                    {
                        isAfterSmallBreakpoint ? <>
                            {backTopButtonText && <div className="footer__left-col">
                                <a href={"#SkipToContent"} className="footer__back-top">
                                    <IconScrollChevron/>

                                    {backTopButtonText}
                                </a>
                            </div>
                            }
                        </> : <div className="footer__right-col">
                            <div className="footer__socials">
                                <div className="footer__socials-links">
								<span className={'visually-hidden'}>
									{socialLabel}
								</span>

                                    {socialLinks.map(({url, text, ariaLabel,icon}, index) => (
                                        <a
                                            href={url}
                                            key={index}
                                            className={`footer__social-link ${index === socialLinks.length - 1 ? 'footer__social-link--last' : ''}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            aria-label={`Visit our ${ariaLabel}`}
                                        >
                                            {parse(icon)}
                                        </a>
                                    ))}
                                </div>

                                <span className="footer__socials-label" aria-hidden={"true"}>
								{socialLabel}
							</span>

                                <span className="footer__socials-circle"/>
                            </div>
                        </div>
                    }

                    {
                        copyright && <div
                            className="footer__copyright"
                            dangerouslySetInnerHTML={{
                                __html: currentCopyright
                            }}
                        />
                    }

                    {
                        isAfterSmallBreakpoint ? <div className="footer__right-col">
                            <div className="footer__socials">
                                <div className="footer__socials-links">
									<span className={'visually-hidden'}>
										{socialLabel}
									</span>

                                    {socialLinks.map(({url, text, ariaLabel,icon}, index) => (
                                        <a
                                            href={url}
                                            key={index}
                                            className={`footer__social-link ${index === socialLinks.length - 1 ? 'footer__social-link--last' : ''}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            aria-label={`Visit our ${ariaLabel}`}
                                        >
                                            {parse(icon)}
                                        </a>
                                    ))}
                                </div>

                                <span className="footer__socials-label" aria-hidden={"true"}>
									{socialLabel}
								</span>

                                <span className="footer__socials-circle"/>
                            </div>
                        </div> : <>
                            {
                                backTopButtonText && <div className="footer__left-col">
                                    <a href={"#SkipToContent"} className="footer__back-top">
                                        <IconScrollChevron/>

                                        {backTopButtonText}
                                    </a>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </footer>
    );
};

export default Footer;
