const slideLeft = {
  hidden: {
    opacity: 1,
    x: '-100%',
  },
  visible: {
    opacity: 1,
    x: '0',
    transition: {
      duration: 0.3,
      type: 'spring',
      damping: 20,
      stiffness: 100,
    },
  },
  exit: {
    opacity: 1,
    x: '-100%',
  },
};

export default slideLeft;
