import { useEffect, useState } from "react";

const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const isClient = typeof window !== "undefined";
        if (!isClient) return;

        const media = window.matchMedia(query);

        const handleMediaChange = (event) => setMatches(event.matches);

        setMatches(media.matches);

        media.addEventListener("change", handleMediaChange);

        return () => media.removeEventListener("change", handleMediaChange);
    }, [query]);

    return matches;
};

export default useMediaQuery;
