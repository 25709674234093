import React, {useEffect, useRef, useState} from "react";
import Tabs from "./Tabs";
import PlansTable from "../PlansTable";

const PlansTableWithTabs = ({plans}) => {
    const emptyTab = {
        title: "",
        plan: {
            ...plans[0].plan,
            price: "",
            benefits: plans[0].plan.benefits.map(benefit => {
                return {
                    ...benefit,
                    benefit: benefit.benefit.map(benefit => {
                        return {
                            ...benefit,
                            type: 'empty',
                        }
                    })
                }
            })
        }
    };
    const plansTableWithTabsRef = useRef(null);
    const [activePlan, setActivePlan] = useState(plans[0]);
    const [activeTab, setActiveTab] = useState(0);
    const handleChange = (activeTab) => {
        setActivePlan(emptyTab);
        setTimeout(() => {
            setActivePlan(plans[activeTab])
            setActiveTab(activeTab)
        });
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const handleScroll = () => {
                const header = document.querySelector('#site-header');
                const headerRect = header?.getBoundingClientRect();
                const myComponentRect = plansTableWithTabsRef.current.getBoundingClientRect();
                const isHeaderOver = headerRect?.bottom > myComponentRect.top && headerRect.top < myComponentRect.bottom;

                header.classList.toggle('header--hidden', isHeaderOver);
            };

            window.addEventListener('scroll', handleScroll);
            handleScroll();

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    return (
        <div ref={plansTableWithTabsRef} className="plans-table__table-with-tabs">
            <Tabs
                plans={plans}
                onChange={handleChange}
                activeTab={activeTab}
            />

            <PlansTable
                plans={[activePlan]}
                withoutHeader
            />
        </div>
    );
}

export default PlansTableWithTabs;
