import React, {useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ContactDropdown from "./ContactDropdown";

const subjectDropdownList = [
    {
        label: 'General request',
        value: 'General request'
    },
    {
        label: 'Gain Theme Support',
        value: 'Gain Theme Support'
    },
    {
        label: 'APP Support',
        value: 'APP Support'
    },
    {
        label: 'Cooperation',
        value: 'Cooperation'
    }
];

const subSubjectDropdownList = [
    {
        label: 'Work for us',
        value: 'Work for us'
    },
    {
        label: 'Partnership',
        value: 'Partnership'
    },
    {
        label: 'I want to request a project',
        value: 'I want to request a project'
    }
];

const projectNeedsDropdownList = [
    {
        label: 'I need a design',
        value: 'I need a design'
    },
    {
        label: 'I have a design, I need development',
        value: 'I have a design, I need development'
    },
    {
        label: 'I have a website, I need tech support and upgrade',
        value: 'I have a website, I need tech support and upgrade'
    },
    {
        label: 'I need marketing',
        value: 'I need marketing'
    },
    {
        label: 'I need consultation',
        value: 'I need consultation'
    }
];

const initialFormData = {
    full_name: "",
    email: "",
    phone: "",
    website: "",
    subject: "General request",
    sub_subject: "Work for us",
    project_needs: [""],
    comments: "",
}

const ContactForm = ({title, description, isBlackText, prefixForFieldsId, ariaParent}) => {
    const [formData, setFormData] = useState(initialFormData);
    const [isFetching, setIsFetching] = useState(false);
    const [isServerError, setIsServerError] = useState(false);
    const [submitMessage, setSubmitMessage] = useState("");
    const [isFormError, setIsFormError] = useState(false);
    const [invalidFields, setInvalidFields] = useState({});
    const [buttonText, setButtonText] = useState("Send");

    function getFormData(object) {
        const formData = new FormData();
        Object.keys(object).forEach(key => formData.append(key, object[key]));

        return formData;
    }

    function resetForm() {
        setFormData(initialFormData);

        setTimeout(() => {
            setSubmitMessage("");
            setButtonText("Send");
        }, 5000)
    }

    const onFormSubmit = async (event) => {
        event.preventDefault();

        setIsFetching(true);
        setSubmitMessage("");
        setButtonText("");
        setInvalidFields({});

        try {
            const response = await fetch("/wp-json/contact-form-7/v1/contact-forms/1050/feedback", {
                body: getFormData(formData),
                method: "POST"
            });
            const responseJSON = await response.json();

            if (!responseJSON.status) {
                console.log(`Error: ${responseJSON.message}`);
                throw new Error("Server error");
            }

            const hasntInvalidFields = !responseJSON?.invalid_fields?.length;

            setIsServerError(hasntInvalidFields && responseJSON?.status !== "mail_sent");
            setSubmitMessage(responseJSON?.message);
            setIsFormError(responseJSON.status !== "mail_sent");

            if (responseJSON.status !== "mail_sent") {
                const invalidMessages = responseJSON.invalid_fields.reduce((obj, currentValue) => {
                    obj[currentValue.into.replace("span.wpcf7-form-control-wrap.", "")] = currentValue.message;

                    return obj;
                }, {});

                setButtonText("Send");
                setInvalidFields(invalidMessages);
                return;
            }

            setButtonText("Thank you");
            setInvalidFields({});
            resetForm();
        } catch (error) {
            setButtonText("Send");
            setIsServerError(true);
            console.log(`Error: ${error}`);
        } finally {
            setIsFetching(false);
        }
    };

    const saveField = (key, value) => {
        if (key === "subject") {
            if (value === "General request") {
                setFormData({
                    ...formData,
                    [key]: value,
                    sub_subject: "Work for us"
                });
            } else {
                setFormData({
                    ...formData,
                    [key]: value,
                    sub_subject: "",
                    project_needs: null
                });
            }

            return;
        }

        if (key === "sub_subject") {
            if (value === "I want to request a project") {
                setFormData({
                    ...formData,
                    [key]: value,
                    project_needs: null
                });
            } else {
                setFormData({
                    ...formData,
                    [key]: value,
                    project_needs: null
                });
            }

            return;
        }

        setFormData({
            ...formData,
            [key]: value
        });
    };

    const fieldsIds = Object.keys(formData).reduce((ids, id) => {
        ids[id] = `${prefixForFieldsId}${id}`;

        return ids;
    }, {});

    return (
        <form
            className={classNames("form", {
                "form--black-text": isBlackText
            })}
            onSubmit={onFormSubmit}
        >
            {title && (
                <h2
                    className="form__title"
                    id={`${ariaParent}-contactTitle`}
                >
                    {title}
                </h2>
            )}

            {description && (
                <p
                    className="form__description"
                    id={`${ariaParent}-contactDescription`}
                >
                    {description}
                </p>
            )}

            <div className="form__input-group">
                <label
                    htmlFor={fieldsIds.full_name}
                    className="form__label"
                >
                    Name <span className="form__label-required-symbol">*</span>
                </label>

                <input
                    type="text"
                    className={classNames("form__input", {
                        "form__input--not-empty": formData["full_name"] !== ""
                    })}
                    id={fieldsIds.full_name}
                    name={fieldsIds.full_name}
                    required="required"
                    value={formData["full_name"]}
                    onChange={(event) => saveField("full_name", event.target.value)}
                />
            </div>

            {
                invalidFields.full_name && <div className="form__input-error-message">
                    <p>
                        {invalidFields.full_name}
                    </p>
                </div>
            }

            <div className="form__input-group">
                <label
                    htmlFor={fieldsIds.email}
                    className="form__label"
                >
                    Email <span className="form__label-required-symbol">*</span>
                </label>

                <input
                    type="email"
                    className={classNames("form__input", {
                        "form__input--not-empty": formData["email"] !== ""
                    })}
                    id={fieldsIds.email}
                    name={fieldsIds.email}
                    required="required"
                    value={formData["email"]}
                    onChange={(event) => saveField("email", event.target.value)}
                />
            </div>

            {
                invalidFields.email && <div className="form__input-error-message">
                    <p>
                        {invalidFields.email}
                    </p>
                </div>
            }

            <ContactDropdown
                options={subjectDropdownList}
                selectedOptions={[formData.subject]}
                onSelect={(payload) => saveField("subject", payload)}
            />

            {
                formData.subject === "General request" && <ContactDropdown
                    options={subSubjectDropdownList}
                    selectedOptions={[formData.sub_subject]}
                    onSelect={(payload) => saveField("sub_subject", payload)}
                />
            }

            {
                formData.sub_subject === "I want to request a project" && <ContactDropdown
                    multiSelect
                    options={projectNeedsDropdownList}
                    selectedOptions={formData.project_needs}
                    onSelect={(payload) => saveField("project_needs", payload)}
                />
            }

            <div className="form__input-group">
                <label
                    htmlFor={fieldsIds.phone}
                    className="form__label"
                >
                    Phone
                </label>

                <input
                    type="tel"
                    className={classNames("form__input", {
                        "form__input--not-empty": formData["phone"] !== ""
                    })}
                    id={fieldsIds.phone}
                    name={fieldsIds.phone}
                    pattern="^[0-9\+]{1,}[0-9\-]{3,15}$"
                    value={formData["phone"]}
                    onChange={(event) => saveField("phone", event.target.value)}
                />
            </div>

            {
                invalidFields.phone && <div className="form__input-error-message">
                    <p>
                        {invalidFields.phone}
                    </p>
                </div>
            }

            <div className="form__input-group">
                <label
                    htmlFor={fieldsIds.website}
                    className="form__label"
                >
                    Website
                </label>

                <input
                    type="text"
                    className={classNames("form__input", {
                        "form__input--not-empty": formData["website"] !== ""
                    })}
                    id={fieldsIds.website}
                    name={fieldsIds.website}
                    value={formData["website"]}
                    onChange={(event) => saveField("website", event.target.value)}
                />
            </div>

            {
                invalidFields.website && <div className="form__input-error-message">
                    <p>
                        {invalidFields.website}
                    </p>
                </div>
            }

            <div className="form__input-group">
                <label
                    htmlFor={fieldsIds.comments}
                    className="form__label"
                >
                    Comments
                </label>

                <input
                    type="text"
                    className={classNames("form__input", {
                        "form__input--not-empty": formData["comments"] !== ""
                    })}
                    id={fieldsIds.comments}
                    name={fieldsIds.comments}
                    value={formData["comments"]}
                    onChange={(event) => saveField("comments", event.target.value)}
                />
            </div>
            {
                invalidFields.comments && <div className="form__input-error-message">
                    <p>
                        {invalidFields.comments}
                    </p>
                </div>
            }

            {
                submitMessage && <div className={classNames("form__message", {
                    "form__message--error": isFormError
                })}>
                    <p>
                        {submitMessage}
                    </p>
                </div>
            }

            {
                isServerError && <div style={{marginTop: "20px"}}>
                    <p style={{color: "red"}}>
                        Sorry, an unexpected error occurred on the server, you can contact us directly on <a
                        style={{color: "red"}} href="mailto:support@utdweb.team">support@utdweb.team</a>
                    </p>
                </div>
            }

            <button
                className="button button--transparent button--large form__submit"
                disabled={isFetching}
                type="submit"
            >
                {buttonText}

                {
                    isFetching && <span className="btn-loader"/>
                }
            </button>
        </form>
    );
};

ContactForm.defaultProps = {
    isBlackText: false,
    prefixForFieldsId: "",
};

ContactForm.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    isBlackText: PropTypes.bool,
    prefixForFieldsId: PropTypes.string
};

export default ContactForm;
