import React, {createRef, useEffect, useRef, useState} from "react"
import PropTypes from "prop-types";
import { graphql, Link, useStaticQuery } from "gatsby";
import IconUTD from "../../icons/IconUTD";
import classNames from "classnames";
import throttle from "../../helpers/throttle";
import SideMenu from "../SideMenu/SideMenu";
import useBodyScrollLock from "../../hooks/useBodyScrollLock";
import HeaderNavigation from "./HeaderNavigation";
import ContactButton from "../Contact/ContactButton";
import useSize from '@react-hook/size';
import { useScrollDirection } from 'react-use-scroll-direction';

const Header = ({ isTransparentHeaderOnTheTop = true, isBlackText = false }) => {
	const { allWp } = useStaticQuery(graphql`
		query Header {
			allWp {
				nodes {
					header {
						acfHeader {
							contactButtonText
							menuButtonText
						}
					}
				}
			}
		}
	`);

	const { scrollDirection } 			= useScrollDirection();
	const [isHiddenMenu, setIsHiddenMenu] = useState(false);

	useEffect(() => {
		scrollDirection && setIsHiddenMenu(scrollDirection !== "UP" && window.scrollY > 0);
	}, [scrollDirection]);

	const header = useRef();
	const [headerStyle, setHeaderStyle] = useState('');

	const acfHeader = allWp?.nodes?.[0]?.header?.acfHeader ?? {};

	const contactButtonText = acfHeader.contactButtonText;
	const menuButtonText = acfHeader.menuButtonText;

	const isSSR = typeof window === "undefined";

	const checkIsScrolled = () => {
		return window.scrollY !== 0;
	};

	const sideMenuContentWrapper = createRef();

	const [isScrolled, setIsScrolled] = useState(0);
	const [isHeaderHidden, setIsHeaderHidden] = useState(false);
	const [isSideMenuOpened, openSideMenu, closeSideMenu] = useBodyScrollLock(sideMenuContentWrapper);

	useEffect(() => {
		const toggleHeader = (e) => {
			const detail = e.detail ?? {};
			const value = detail.value ?? !isHeaderHidden;

			setIsHeaderHidden(value);
		};

		document.addEventListener("header:toggle", toggleHeader);

		return () => {
			document.removeEventListener("header:toggle", toggleHeader);
		}
	}, []);

	useEffect(() => {
		if (!isSSR) {
			setIsScrolled(checkIsScrolled());
		}
	}, [isSSR]);

	useEffect(() => {
		if(!isTransparentHeaderOnTheTop) {
			return null;
		}

		const handleScroll = throttle(() => {
			setIsScrolled(checkIsScrolled());
		}, 150);

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [isTransparentHeaderOnTheTop]);

	const headerClasses = classNames({
		"header": true,
		"header--black-text": isBlackText,
		"header--hidden": isHeaderHidden,
		"header--filled": !isTransparentHeaderOnTheTop || isScrolled,
		"header--hidden-menu": isHiddenMenu
	});
	const [, height] = useSize(header);

	useEffect(() => {
		const styleElement = document.createElement('style');

		styleElement.id = 'headerHeight';

		document.head.appendChild(styleElement);

		return () => {
			document.head.removeChild(styleElement);
		};
	}, []);

	useEffect(() => {
		const styleElement = document.getElementById('headerHeight');

		if (styleElement) {
			styleElement.textContent = headerStyle;
		}
	}, [headerStyle]);

	useEffect(() => {
		setHeaderStyle(`
		:root {
			--header-height: ${height}px;
		}`)
	},[height]);

	return (
		<>
			<a
				id={"SkipToContent"}
				href="#MainContent"
				aria-label="Skip to content"
				className="visually-hidden"
			>
				Skip to content
			</a>

			<header id="site-header" className={headerClasses} ref={header}>
				<div className="header__wrapper container">
					<div className="header__columns">
						<div className="header__column header__column--left">
							<Link to="/" className="header__logo-link" aria-label="Go to homepage">
								<IconUTD className="header__logo" />
							</Link>
						</div>

						<div className="header__column header__column--right">
							<HeaderNavigation />

							<ContactButton contactButtonText={contactButtonText} className="header__contact-button" />

							{menuButtonText && (
								<button type="button" className="header__menu-button" onClick={openSideMenu} aria-haspopup={"menu"}>
									{menuButtonText}

									<span className="header__menu-button-circle" />
								</button>
							)}

						</div>
					</div>
				</div>
			</header>

			{menuButtonText && (
				<SideMenu
					ref={sideMenuContentWrapper}
					isSideMenuOpened={isSideMenuOpened}
					closeSideMenu={closeSideMenu}
				/>
			)}
		</>
	);
};

Header.propTypes = {
	isTransparentHeaderOnTheTop: PropTypes.bool,
	isBlackText: PropTypes.bool
};

export default Header;
