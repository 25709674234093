import React from "react";
import {Text} from "../index";
import {GatsbyImage as Image, getImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
const CustomCard = ({
    description,
    image,
    link,
    title,
}) => {
    const mediaMarkup = () => {
        const gatsbyImage = getImage(image.localFile);

        return (
            <Image
                className="custom-popup__image"
                image={gatsbyImage}
                alt={image.altText || title}
                title={image.altText || title}
            />
        );
    }

    return (
        <div className="custom-popup__card">
            {image && (
                <div className="custom-popup__media">
                    {mediaMarkup()}
                </div>
            )}

            <div className="custom-popup__information">
                <Text as="h2" appearance="heading5" className="custom-popup__title">
                    {title}
                </Text>

                <Text as="p" appearance="paragraph" className="custom-popup__description">
                    {description}
                </Text>

                <div className="custom-popup__actions">
                    <Link
                        to={link.url}
                        className="custom-popup__link"
                        target={link.target}
                    >
                        {link.title}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default CustomCard;
