import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

const HeaderNavigation = () => {
	const { wpMenu: menu } = useStaticQuery(graphql`
		query HeaderNavigation {
			wpMenu(locations: {eq: HEADER}) {
				id
				menuItems {
					nodes {
						url
						label
						id
					}
				}
			}
		}
	`);

	const menuItems = menu?.menuItems?.nodes;

	return (
		<>
			{Boolean(menuItems.length) && (
				<nav className="header__menu-wrapper" aria-label="Primary menu">
					<ul className="header__menu">
						{menuItems.map(({ label, url, id }) => (
							<li key={id} className="header__menu-item">
								<Link className="header__menu-item-link" to={url}>
									{label}
								</Link>
							</li>
						))}
					</ul>
				</nav>
			)}
		</>
	);
};

export default HeaderNavigation;
